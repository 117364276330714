import {
  ref, onMounted, getCurrentInstance, reactive
} from 'vue'
import { useStore } from 'vuex'
import { getAllItemsKategoriBarang, priceUseCase } from '@/domain/usecase'
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

export default {
  props: ['data', 'fromItemsId', 'catId'],
  emits: ['reloadData', 'closeSidebar'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const { $confirm, $toast, $strInd } = app!.appContext.config.globalProperties
    const store = useStore()
    const itemId = ref(null) as any
    const itemIdDuplicate = ref(null) as any
    const itemOptions = ref([])
    const itemOptionSelected = ref([])
    const dataEdit = ref(props.data)
    const dataForm = reactive({
      itemId: null,
      itemIdDuplicate: [] as any
    })
    const submitted = ref(false)
    const canAddItem = ref(true)
    let rules
    if (props.fromItemsId) {
      rules = {
        itemId: {
          required: helpers.withMessage('Tipe barang harus dipilih', required)
        },
        itemIdDuplicate: {
          required: helpers.withMessage('Duplikasi dari tipe barang lain harus dipilih', required)
        }
      }
    } else {
      rules = {
        itemId: {
          required: helpers.withMessage('Tipe barang harus dipilih', required)
        }
      }
    }

    const v$ = useVuelidate(rules, dataForm)

    const closeSidebar = () => {
      emit('closeSidebar')
    }

    const initItemIdFromProps = () => {
      console.log('dataEdit', dataEdit.value)
      // itemOptionSelected.value = dataEdit.value.MsHargaKategoris.map((val: any) => ({
      //   value: val.KategoriId,
      //   label: val.Kategori.Nama
      // }))
      itemOptionSelected.value = dataForm.itemIdDuplicate.map((val: any) => ({
        value: val.Id,
        label: val.GoodCategoryName
      }))
      console.log('itemOptionSelected', itemOptionSelected.value)
      // itemOptions.value = itemOptions.value.filter((filter: any) => dataEdit.value.MsHargaKategoris.filter((msHarga: any) => filter.value === msHarga.KategoriId).length === 0)
      itemOptions.value = itemOptions.value.filter((data: any) => dataForm.itemIdDuplicate.filter((selectedData: any) => data.value === selectedData.GoodCategoryId).length === 0)
      console.log('itemOptions', itemOptions.value)
      if (itemOptions.value.length === 0) {
        canAddItem.value = false
      }
    }

    const checkPropsFromItemId = () => {
      if (props.fromItemsId) {
        console.log('props item', props)
        dataForm.itemIdDuplicate = props.fromItemsId
      }
    }

    const getAllData = async () => {
      checkPropsFromItemId()
      const response = await getAllItemsKategoriBarang()
      if (!response.error) {
        itemOptions.value = response.result.map((x: any) => ({
          value: x.Id,
          label: x.Nama
        }))
        initItemIdFromProps()
      } else {
        $toast.add({
          severity: 'error', detail: 'Gagal mengambil data, silahkan coba kembali', group: 'bc', life: 3000
        })
      }
    }

    const duplicateTypeItems = async (data: any) => {
      console.log('data', data)
      store.dispatch('showLoading')
      const response = await priceUseCase.duplicateTypeItems(props.catId, data)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 3000
        })
        store.dispatch('hideLoading')
      } else {
        $toast.add({
          severity: 'success', detail: $strInd.toast.successSubmit, group: 'bc', life: 3000
        })
        emit('reloadData')
        closeSidebar()
      }
      // console.log('duplicateItems', response)
    }

    const submitData = (isFormValid: boolean) => {
      // console.log('masukk', isFormValid)
      console.log('catId', props.catId)
      submitted.value = true
      if (!isFormValid) {
        return
      }
      const data = {} as any
      // if (dataForm.itemIdDuplicate !== null && dataForm.itemIdDuplicate !== '') {
      // data.FromHdId = dataEdit.value.Id
      // data.FromKategoriId = dataForm.itemIdDuplicate
      data.forGoodCategoryId = dataForm.itemId
      // console.log('submited data', data)

      duplicateTypeItems(data)
      // }
    }

    onMounted(() => {
      getAllData()
    })

    return {
      v$,
      canAddItem,
      dataEdit,
      itemId,
      itemOptions,
      itemIdDuplicate,
      itemOptionSelected,
      submitted,
      closeSidebar,
      submitData,
      dataForm
    }
  }
}
